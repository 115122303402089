$gray: #434242;
$light-gray: #9c9c9c;
$white: #ffffff;
$black: #000000;

* {
	margin: 0;
	padding: 0;
}

body {
	background-color: $white;
	font-size: 14px;
	font-family: monospace;
	color: $gray;
	letter-spacing: 2px;

	&.font {
		&.font--inconsolata {
			font-family: 'Inconsolata';
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

.h1 {
	@extend h1;
}

.h2 {
	@extend h2;
}

.h3 {
	@extend h3;
}

.h4 {
	@extend h4;
}

.h5 {
	@extend h5;
}

.h6 {
	@extend h6;
}

p {
	margin-bottom: 1rem;
	line-height: 1.6;
}

svg { 
	width: 1rem;

	path {
		transition: fill 0.3s ease-in-out;
	}
}

a {
	color: $light-gray;
	transition: color 0.3s ease-in-out;
	
	&:active,
	&:focus,
	&:hover {
		color: $gray;

		svg path {
			fill: $gray;
		}
	}
}

.wow {
  visibility: hidden;
}

.animated {
  animation-duration: 0.975s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

.hide {
	display: none !important;
}

#app {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 1200px;
	height: 100vh;
	margin-left: auto;
	margin-right: auto;
}

#app > main {
	width: 350px;
	margin-left: 100px;
}

#name {
	display: block;

	&.animate {
		@extend .fadeInUp;
		animation-duration: 1s;
	}
}

article {
	header {
		position: relative;

		.bar {
			content: '.';
			position: absolute;
			left: -3rem;
			bottom: 6px;
			display: inline-block;
			width: 2rem;
			height: 1px;
			background-color: $gray;
			color: transparent;
		}
	}

	footer {
		ul.links {
			list-style: none;
			
			> li {
				display: inline-block;
				margin-right: 0.8rem;
			}
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	#app > main {
		width: 300px;
		margin-left: 50px;
	}
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}
	
	h6 {
		font-size: 14px;
	}

	#app > main {
		margin-left: 25px;
	}

	article {
		header {
			.bar {
				display: none;
			}
		}
	}
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
	#app > main {
		width: 100%;
		margin-left: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
